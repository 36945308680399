.okta-sign-in-header {
  padding: 10px 10px !important;
}

.okta-sign-in-header img {
  max-height: 200px !important;
}

#okta-sign-in .beacon-container.beacon-small .beacon-blank {
  width: auto !important;
  left: 0 !important;
}

#okta-sign-in .auth-footer {
  display: none;
}
