.relay-configuration {
  dt,
  dd {
    align-self: center;
  }
}

.relay-configuration-wrapper {
  display: flex;
  align-items: baseline;
  gap: 0 10px;
}
