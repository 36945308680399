.error {
  .title {
    font-weight: bold;
    padding: 0 0 20px 0;
  }

  .description, .action {
    padding: 10px 0 0 0;
  }
}

.explanationNote {
  margin-top: 20px;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.progressBar {
  text-align: center;
  p {
    position: absolute;
    width: 100%;
    bottom: 2px;
    z-index: 1;
  }
  .MuiLinearProgress-root {
    height: 20px !important;
  }
}

ul#retainedDataList {
  padding: 5px;
  li {
    list-style-type: square;
    list-style-position: inside;
    padding-bottom: 5px;
  }
}
