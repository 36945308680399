.m0 {
  margin: 0 !important;
}

.staticSection {
  display: grid;
  grid-template-columns: 125px auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 10px 0;
  align-items: center;

  dt {
    font-weight: bold;
  }

  dd.labeled {
    display: grid;
    grid-template-columns: 62.5px auto;
  }

  .gsmQuality {
    display: grid;
    grid-template-columns: 28px 28px auto;
    grid-template-rows: 16px auto;
  }
  .gsmQualityIcon {
    margin-top: -6px;
  }
  .gsmNwTechIcon {
    margin-top: -2px;
    color: #505050;
    font-weight: bold;
    font-size: 12px;
    font-family: Verdana, sans-serif;
  }
  .gsmQualityText {
    margin-left: 12px;
  }

  .battery {
    display: grid;
    grid-template-columns: 28px auto;
    grid-template-rows: 16px auto;
  }
  .batteryIcon {
    margin-left: -4px;
    margin-top: -6px;
  }
}
.grid2-1 {
  .item-2 {
    hr.configOverview {
      display: none;
    }
  }
}

@media only screen and (max-width: 640px) {
  .grid2-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 0;

    .item-1 {
      order: 3;
    }
    .item-2 {
      order: 2;

      hr.configOverview {
        display: block;
      }
    }
  }
}
