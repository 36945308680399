.TopBar {
  display: grid;
  grid-template-columns: 154px auto;
  align-items: center;
}

.TopBar > a > img {
  max-width: 154px;
  max-height: 45px;
  object-fit: scale-down;
}
