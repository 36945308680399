.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  h2 {
    color: #f9423a;
    margin: 30px 0 10px 0;
    font-size: 1.5em;
    font-weight: bold;
  }

  hr {
    margin: 10px -14px 25px;
    height: 1px;
    color: #f9423a;
    background-color: #f9423a;
    border: none;
  }

  .failedToLoadInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    > span {
      padding: 0 0 0 10px;
    }
  }
}

.loadingSpinner {
  text-align: center;
}

.importButton {
  align-self: flex-end;
}

.importSpinner {
  text-align: center;
  margin-bottom: 14px;
}

.successIcon {
  color: green;
  margin-right: 10px;
}

.errorIcon {
  color: red;
  margin-right: 10px;
}

.importValue {
  display: flex;
  align-items: center;
}

input[type='text'] {
  color: black;
}
