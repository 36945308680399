.grid2-1 {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  row-gap: 10px;
}

.grid3-1 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}

.flex-grid {
  display: flex;
}

.fullWidth {
  grid-column: 1/-1;
}

.main {
  padding-bottom: 10px;
}
