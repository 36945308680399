.main {
  margin: 10px 25px;
  font-family: Open Sans, sans-serif;
}

/* Hide All input arrows on number inputfields */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
