
.state {
}

.stateDetails {
  padding: 10px 20px;
  max-width: 600px;
  min-width: 300px;
}

.stateDetails dt {
  font-weight: bold;
}

.stateDetails dd {
  margin: 5px 0 10px 20px
}

.state DIV {
  display: flex;
  align-items: center;
}

.pending {
  border-radius: 25px;
  background: rgb(219,234, 254);
  color: rgb(29, 78, 216);
}

.done {
  border-radius: 25px;
  background: rgb(220,252, 231);
  color: rgb(21, 128, 61);
}

.pending > div, .done > div {
  font-size: 12px;
  display:inline-block;
  padding: 4px 8px;
  /*clip-path: inset(0 22px 0 0);
  animation: l 2s steps(7) infinite;*/
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}