.signalStrength0 {
  color: #ef4444; //--color-danger-500
}
.signalStrength1 {
  color: #ef4444; //--color-danger-500
}
.signalStrength2 {
  color: #f59f0b; //--color-warning-500
}
.signalStrength3 {
  color: #22c55e; //--color-success-500
}
.signalStrength4 {
  color: #22c55e; //--color-success-500
}

.batteryUnknown {
  color: #ef4444; //--color-danger-500
}
.batteryBelow30 {
  color: #ef4444; //--color-danger-500
}
.batteryBelow60 {
  color: #f59f0b; //--color-warning-500
}
.batteryBelow90 {
  color: #22c55e; //--color-success-500
}
.battery100 {
  color: #22c55e; //--color-success-500
}
