.input-filtering {
  dt,
  dd {
    align-self: center;
  }
}

.input-filtering-wrapper {
  display: flex;
  align-items: baseline;
  gap: 0 10px;
}