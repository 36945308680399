.output-mode {
  dt,
  dd {
    align-self: center;
  }
}

.output-mode-wrapper {
  display: flex;
  align-items: baseline;
  gap: 0 10px;
}
