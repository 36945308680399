.advanced-sensor-data-row {
  align-items: flex-start;
}

.advanced-sensor-data-cell {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & div {
    padding-bottom: 4px;
  }
}

.advanced-sensor-data-details {
  margin: -18px 0 !important;
  padding: 7px !important;
}

.advanced-sensor-activity {
  margin-right: 8px;

  & span {
    white-space: nowrap;
  }
}

.advanced-sensor-detail-label {
  font-style: italic;
  font-weight: 600;
  margin-right: 8px;
}
