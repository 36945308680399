@import "../DeviceState/StateStatus";

.error {
  .title {
    font-weight: bold;
    padding: 0 0 20px 0;
  }

  .description, .action {
    padding: 10px 0 0 0;
  }
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.progressBar {
  text-align: center;
  p {
    position: absolute;
    width: 100%;
    bottom: 2px;
    z-index: 1;
  }
  .MuiLinearProgress-root {
    height: 20px !important;
  }
}

.adcInput {
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    border-radius: 5px;
    background-color: #F5F5F5;
  }
}

.inputLabel {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pendingWrapper {
  display: flex;
  justify-content: flex-end;

  .state {
    padding: 0 10px 0 0;
  }
}


.pendingInput {
  background: rgb(219,234, 254);
  border-radius: 6px;
}

.gridRightAlign {
  text-align: right;
}