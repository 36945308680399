.totalKm {
  display: flex;
}

.totalKm > div:nth-child(1) {
  flex-grow: 1;
  width: auto;
}

.totalKm > div:nth-child(2) {
  width: 180px;
}
