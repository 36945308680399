
.root {
  margin-top: 40px;
  margin-left: 10px;
}

.helpIcon {
  vertical-align: middle;
  margin-right: 5px;
  font-size: 16px !important;
}
