.extended {
  display: inline-grid;
}

.extendedItemReadonly {
  margin: 4px 0;
}

.extendedItemEditable {
  display: flex;
}

.extendedKey {
  font-weight: bold;
}

.noExtendedMetadata {
  color: gray;
}

.saveButton {
  margin-top: 10px;
}

.fullWidthDatePicker {
  width: 100%;
}

.noMetadata {
  font-style: italic;
}

.deleteIcon {
  color: red;
}

button.deleteButton {
  padding-top: 20px;
}

.addIcon {
  color: green;
}

button.addButton {
  padding: 5px;
}
