.can-data-text {
  display: flex;
  align-items: center;
}

.can-data-list {
  width: 100%;
  min-width: 250px;
  max-width: 360px;
}

.can-data-list p {
  display: flex;
  flex-direction: column;
}

.can-data-list-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
